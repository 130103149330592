.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.website-rights {
    color: #fff;
    margin-bottom: 16px;
}
  
@media screen and (max-width: 768px) {
}