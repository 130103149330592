* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .container {
    margin: 1rem;
    text-align: center;
  }
  
  .nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
  }
  
  .site-title {
    font-size: 2rem;
  }
  
  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
  }
  
  .nav li.active {
    background-color: #555;
  }
  
  .nav li:hover {
    background-color: #777;
  }

  .nav button {
    background-color: #68eefa; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }